import React, { Component } from "react";
import { Button, CardContent, CardHeader, Grid, Header, Image, LabelDetail } from "semantic-ui-react";
import UserDetailForm from "../components/forms/user-detail-form";
import { connect } from "react-redux";
import { rejectIfAnyNull } from "../controllers/utilities/env-checks";
import { formatQuery } from "../controllers/utilities/string-utils";
import cookie from "react-cookies";
import { navigate } from "gatsby";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import NavigationBar from "../components/forms/navigation-bar";


class Sdkcallback extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ready:false,
            mode:""
        };
        // this.state.mode = detectOrient();
        rejectIfAnyNull(this.props.session);
    }
    
    componentDidMount() {
        // window.addEventListener('resize', this.getDirection);
        const backurl = this.props.location.pathname + this.props.location.search;
        cookie.save("csbackurl", backurl);
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.getDirection);
    }

    getDirection = () => {
        // this.setState({ state: this.state });
        const mode = detectOrient();
        if (mode === "PORTRAIT") {
            if (typeof window !== `undefined`) {
                navigate(`/sdk-callback/${this.props.location.search}`);
            }
        }
    }

    render() {
        // let iconJgg = icon;
        // if (environmentMode === "SANDBOX") iconJgg = iconGg;
        if (this.state.mode === '') {
            const mode = detectOrient();
            if (mode === "PORTRAIT") {
                if (typeof window !== `undefined`) {
                    navigate(`/sdk-callback/${this.props.location.search}`);
                }
            } else {
                this.setState({
                    ready: true,
                    mode: "LANDSCAPE"
                });
            }
        }
        const mode = this.state.mode;

        let { transactionId, gameId, inGameId, showReturnBtn,platform } = formatQuery(this.props.location.search);
        if (showReturnBtn) {
            let { accessToken, attributionId } = formatQuery(this.props.location.search);
            let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
            cookie.save("_showReturnBtn", "1", {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
            cookie.save("_gameId", gameId, {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
            cookie.save("_accessToken", accessToken, {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
            cookie.save("_transactionId", transactionId, {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
            cookie.save("_attributionId", attributionId, {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
        } else {
            showReturnBtn = cookie.load("_showReturnBtn");
            platform = cookie.load("_platform");
        }
        if (showReturnBtn) {
            if ((!platform) || (platform === "unknown")){
                cookie.save('_platform', "unknown");
                cookie.save('_platform_operation', "unknown");    
            }else{
                cookie.save('_platform', "h5");
                cookie.save('_platform_operation', "pay");    
            }
        }
        if (gameId === undefined) {
            gameId = cookie.load("_gameId");
        }
        if (transactionId === undefined) {
            transactionId = cookie.load("_transactionId");
        }
        return (
            <>
            {(this.state.ready) && (<div className="whiteDiv" textAlign='center' style={{
                width: "100%",
                minHeight: "100vh",
                textAlign: "center",
                margin: "0 auto"
            }} >

<NavigationBar mode={mode} platform={"h5"} showLogo={1} showTitle={1} onBack={async () => {
                            if (showReturnBtn === "1") {
                                AndroidComm.sendRawMessage({
                                    success: false,
                                    message: "user returned"
                                });
                            } else {
                                navigate('/confirm-purchase');
                            }

                        }}/>

                <div style={{ Width: "100%", height: "100vh", paddingTop: "80px", margin: "0 auto" }}>
                    <div style={{ padding: "3vh" }}>

                        <Grid textAlign='center' style={{
                            margin: '0',
                            width: '100%',
                            backgroundColor: "transparent",
                        }} verticalAlign='top'>

                            
                                    <Grid.Column style={{ width: "90%" }}>

                                        <UserDetailForm session={this.props.session} transactionId={transactionId} gameId={gameId} ingameId={inGameId} showReturnBtn={showReturnBtn} mode="LANDSCAPE" />
                                    </Grid.Column>
                                
                        </Grid>
                    </div></div>
            </div>)}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        session: state.session
    };
}

export default connect(mapStateToProps)(Sdkcallback);

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "";
    }
}